.desktop-view{
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  @media screen and (max-width: 1230px) {
    justify-content: flex-start;
    margin-top: 10vw;
  } 
  padding-bottom: 9v;
  padding-left: calc(7vw - #{$gutter*2});
  .heading{
    color:$eggshell;
    @include e-ptype(36);
    margin: 0 0 $gutter*2;
  }
  .desktop-text{
    @include ptype-base-regular(2);
    margin: 0 0 40px;
  }
}
.form-wrapper{
  display: flex;
  width: 100%;
  max-width: 570px;
  margin:0 0 $gutter*2;
  .inline-form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 570px;
  }
  .form-control{
    display: flex;
    flex-grow: 1;
    padding-right: 8px;
    position: relative;
    flex-direction: column;
    margin-bottom: 1rem;

    img{
      position: absolute;
      top:0;
      left:0;
      margin: 17px 0 0 16px;
      user-select: none;
      pointer-events: none;
    }
    input{
      background: $white;
      width: 100%;
      height: 58px;
      padding: 0 16px 0 62px;
      border-radius: 8px;
      border:solid 1px $white;
      display: flex;
      flex-grow: 1;
      min-width: 250px;
      @include ptype-base-regular(0);
      font-weight: 700;
      color: $black;
      @include main-transition;
      &:focus{
        box-shadow: 0 2px 5px rgba(0,0,0,.25);
      }
      &:focus-visible{
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: #00000090;
        opacity:1;
        font-weight: 400;
      }
      &::-moz-placeholder {
        color: #00000090;
        opacity:1;
        font-weight: 400;
      }
    }
    .error{
      display: inline-flex;
      margin-top: 3px;
      font-size: 16px;
    }
  }
  .btn-submit{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $holmiogreen;
    color:$white;
    border:0;
    outline: none;
    border-radius: 8px;
    padding: 0 16px;
    @include ptype-base-regular(0);
    font-weight: 700;
    color:$eggshell;
    margin-bottom: auto;
    height: 58px;
    @include main-transition;
    cursor: pointer;
    &:hover,
    &:focus{
      box-shadow: 0 2px 5px rgba(0,0,0,.25);
    }
    &.disabled{
      pointer-events: none;
      background: #eeeeee;
      color:#0005;
      pointer-events: none;
    }
  }
}
.cont-wrapper{
  display: flex;
  align-items: center;
 
  flex-direction: row;
  svg{
    margin-right: 12px;
  }
  .cont-text{
    display: inline-flex;
    width: auto;
    margin: 0;
    @media screen and (max-width: 1230px) {
      color: #161616;
    }
  }
}