.section-gallery{
	width: 100%;
	display: flex;
	margin: 0;
	padding: 80px 0;
	background-color: $white;
	min-height: 100vh;
	@include max-lg{
		min-height: auto;
		padding:80px $gutter;
	}
	
	.gallery-wrapper{
		border-radius: 15px;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		position: relative;
		padding:120px 128px 120px 82px;
		@include max-lg{
			padding: 80px 40px;
		}
		@include max-sm{
			padding: $gutter*2 $gutter*2 $gutter*4 ;
			background-position: 30% bottom;
    	background-size: 170%;
			.page-customer & {
				background-size: 182%;
			}
		}
		> .row > [class^="col-"]{
			flex-direction:column;
		}
		.space{
			justify-content: space-between;
			padding-left: 26px;
			@include max-sm{
				padding-left: $gutter;
			}
		}
		.stores{
			.btn-transparent{
				margin: 8px 8px 0 0;
				display: inline-flex;
				@include max-sm{
					margin: 8px 16px 0 0;
				}
			}
		}
		.gallery-title{
			font-family:$heading;
			font-size: 36px;
			font-style: normal;
			font-weight: 800;
			line-height: 40px;
			letter-spacing: 0px;
			text-align: left;
			color: $eggshell;
			margin: 0 0 32px;
			@include max-md{
				margin-top: 40px;
			}
			@include max-sm{
				margin-top: $gutter;
				font-size: 34px;
				line-height:120%;
			}
		}
		.store-title{
			@include ptype-base-0("bold");
			margin: 0;
			@include max-sm{
				margin: 0 0 16px;
			}

		}
		.content{

			ul{
				margin: 0 0 $gutter*3;
				padding: 0;
				list-style: none;
				@include max-sm{
					margin: 0 0 $gutter*4;
				}
				li{
					@include ptype-base-plus-3("bold");
					color: $white;
					padding-left:56px;
					margin-bottom: $gutter*2;
					position: relative;
					@include max-sm{
						font-size: 17px;
						line-height: 19px;
						padding-left: 52px;
					}
					&::before{
						content:"";
						background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAcCAMAAAA6Aj1XAAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMAECAwP0BQX29wf4CPkJ+gr7/P3+/jWzPKAAAApElEQVR42o2SVQLEQAhDoe7K3P+o66kOsO+r8iCpkA8n5JMPIuw4aSvhRWMvmsMXYVd6sZbsS1KRRjIF0OiBtUCaE32Vk+esGtf9nHtI17c1vi61CFwgSXazXnwXpissBABGlfZl7bWYLhTbgmKzBrrThQvKp55tCySrbYEchtILNLu1MunMyvu6wKiXkkmK+g71J5JM8G0TcuHVi8RPxPQPhvUE6/kWOZJt9gsAAAAASUVORK5CYII=') 0 0 no-repeat;
						width: 37px;
						height: 26px;
						display: flex;
						position: absolute;
						top:5px;
						left:0;
					}
				}
			}
		}
	}
	.image-group{
		.left,
		.right{
			display: flex;
			flex-direction: column;
			img{
				border-radius: 8px;
			}
		}
		.left{
			padding-right: 28px;
			@include max-sm{
				padding-right: $gutter;
			}
		}
		.right{
			align-items: flex-start;
			justify-content: center;
		}
		.gallery{
			&-1{
				.img1{
					width: 100%;
					display: flex;
					align-items: flex-end;
					justify-content: flex-end;
					.inner{
						width: 100%;
						max-width: 176px;
						height: 0;
						padding-top: 44%;
						position: relative;
						overflow: hidden;
						display: flex;
						border-radius: 8px;
						@include max-md{
							padding-top: 32%;
						}
						@include max-sm{
							padding-top: 50%;
						}
						@include max-xs{
							padding-top: 90%;
						}
						>img{
							position: absolute;
							z-index: 1;
							top:50%;
							left:50%;
							transform: translate(-50%,-50%);
							object-fit: cover;
							max-width: 176px;
						}
					}
				}
				.img2{
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: flex-end;
					margin-top: 40px;
					max-width: 382px;
					@include max-sm{
						margin-top: $gutter*2;
					}
					.inner{
						width: 100%;
						height: auto;
						position: relative;
						overflow: hidden;
						display: flex;
						border-radius: 8px;
						>img{
							width: 100%;
							max-width: 382px;
						}
					}
				}
				.img3{
					width: 100%;
					display: flex;
					align-items:center;
					justify-content: center;
					overflow: hidden;
					border-radius: 8px;
					max-width: 163px;
					>img{
						width: 100%;
						max-width: 163px;
					}
				}
			}
			&-2{
				.left{
					align-items: center;
					justify-content: center;
				}
				.img1{
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					.inner{
						width: 100%;
						max-width: 176px;
						height: 0;
						padding-top: 44%;
						position: relative;
						overflow: hidden;
						display: flex;
						border-radius: 8px;
						@include max-md{
							padding-top: 32%;
						}
						@include max-sm{
							padding-top: 50%;
						}
						@include max-xs{
							padding-top: 90%;
						}
						>img{
							position: absolute;
							z-index: 1;
							top:50%;
							left:50%;
							transform: translate(-50%,-50%);
							object-fit: cover;
							max-width: 176px;
						}
					}
				}
				.img2{
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: flex-end;
					margin-bottom: 40px;
					max-width: 382px;
					@include max-sm{
						margin-bottom: $gutter*2;
					}
					.inner{
						width: 100%;
						height: auto;
						position: relative;
						overflow: hidden;
						display: flex;
						border-radius: 8px;
						>img{
							width: 100%;
							max-width: 382px;
						}
					}
				}
				.img3{
					width: 100%;
					display: flex;
					align-items:center;
					justify-content: center;
					overflow: hidden;
					border-radius: 8px;
					margin-bottom: $gutter*2;
					max-width: 163px;
					>img{
						width: 100%;
						max-width: 163px;
					}
				}
			}
		}
	}
}