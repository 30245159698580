.page-holmio{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 92vh;
  width: 100vw;
  @include min-sm{
    min-height: 100vh;
  }
  @import "page-index";
}

.holmio-layout{
  &.no-padding{
    .holmio-main{
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 0;
    }
  }
  @import "page-links";
  @import "page-customer";
  @import "page-farmer";
}