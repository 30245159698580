
@mixin nogutter{
  padding:0;
  .row{
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    > [class^="col-"]{
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// GRID
.container{
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding:0 $gutter;
  // @include min-xs{ max-width: 720px; }
  // @include min-md{ max-width: 976px; }
  // @include min-lg{ max-width: 1152px; }
  @include min-lg{ max-width:calc(1280px + #{$gutter*2})}
  &.no-gutters{ @include nogutter;}
  &-fluid{
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding:0 $gutter;
    &.no-gutters{ @include nogutter;}
  }
  &.padding-double{
    padding:0 #{$gutter*2};
  }
}

.row{
  margin-right: -$gutter;
  margin-left: -$gutter;
  width: calc(100% + #{$gutter*2});
  display: flex;
  flex-wrap: wrap;
  > [class^="col-"]{
    display: flex;
  }
}

// Used mixins - just here !!!
// COLUMNS
@mixin col-pad{padding:0 $gutter;width:100%;position:relative;}
@mixin col1 { max-width: 8.3333% ; flex:0 0 8.3333%  ; @include col-pad; }
@mixin col2 { max-width: 16.6666%; flex:0 0 16.6666% ; @include col-pad; }
@mixin col3 { max-width: 25%     ; flex:0 0 25%      ; @include col-pad; }
@mixin col4 { max-width: 33.3333%; flex:0 0 33.3333% ; @include col-pad; }
@mixin col5 { max-width: 41.6666%; flex:0 0 41.6666% ; @include col-pad; }
@mixin col6 { max-width: 50%     ; flex:0 0 50%      ; @include col-pad; }
@mixin col7 { max-width: 58.3333%; flex:0 0 58.3333% ; @include col-pad; }
@mixin col8 { max-width: 66.6666%; flex:0 0 66.6666% ; @include col-pad; }
@mixin col9 { max-width: 75%     ; flex:0 0 75%      ; @include col-pad; }
@mixin col10{ max-width: 83.3333%; flex:0 0 83.3333% ; @include col-pad; }
@mixin col11{ max-width: 91.6666%; flex:0 0 91.6666% ; @include col-pad; }
@mixin col12{ max-width: 100%    ; flex:0 0 100%     ; @include col-pad; }
// OFFSET
@mixin off0 { margin-left: 0;       }
@mixin off1 { margin-left: 8.3333%; }
@mixin off2 { margin-left: 16.6666%;}
@mixin off3 { margin-left: 25%;     }
@mixin off4 { margin-left: 33.3333%;}
@mixin off5 { margin-left: 41.6666%;}
@mixin off6 { margin-left: 50%;     }
@mixin off7 { margin-left: 58.3333%;}
@mixin off8 { margin-left: 66.6666%;}
@mixin off9 { margin-left: 75%;     }
@mixin off10{ margin-left: 83.3333%;}
@mixin off11{ margin-left: 91.6666%;}
@mixin off12{ margin-left: 100%;    }

.col{
  &-1 {@include col1;}
  &-2 {@include col2;}
  &-3 {@include col3;}
  &-4 {@include col4;}
  &-5 {@include col5;}
  &-6 {@include col6;}
  &-7 {@include col7;}
  &-8 {@include col8;}
  &-9 {@include col9;}
  &-10{@include col10;}
  &-11{@include col11;}
  &-12{@include col12;}
}
.offset{
  &-0 {@include off0;}
  &-1 {@include off1;}
  &-2 {@include off2;}
  &-3 {@include off3;}
  &-4 {@include off4;}
  &-5 {@include off5;}
  &-6 {@include off6;}
  &-7 {@include off7;}
  &-8 {@include off8;}
  &-9 {@include off9;}
  &-10{@include off10;}
  &-11{@include off11;}
  &-12{@include off12;}
}

@include min-sm{
  .col{
    &-sm-1 {@include col1;}
    &-sm-2 {@include col2;}
    &-sm-3 {@include col3;}
    &-sm-4 {@include col4;}
    &-sm-5 {@include col5;}
    &-sm-6 {@include col6;}
    &-sm-7 {@include col7;}
    &-sm-8 {@include col8;}
    &-sm-9 {@include col9;}
    &-sm-10{@include col10;}
    &-sm-11{@include col11;}
    &-sm-12{@include col12;}
  }
  .offset{
    &-sm-0 {@include off0;}
    &-sm-1 {@include off1;}
    &-sm-2 {@include off2;}
    &-sm-3 {@include off3;}
    &-sm-4 {@include off4;}
    &-sm-5 {@include off5;}
    &-sm-6 {@include off6;}
    &-sm-7 {@include off7;}
    &-sm-8 {@include off8;}
    &-sm-9 {@include off9;}
    &-sm-10{@include off10;}
    &-sm-11{@include off11;}
    &-sm-12{@include off12;}
  }
}

@include min-md{
  .col{
    &-md-1 {@include col1;}
    &-md-2 {@include col2;}
    &-md-3 {@include col3;}
    &-md-4 {@include col4;}
    &-md-5 {@include col5;}
    &-md-6 {@include col6;}
    &-md-7 {@include col7;}
    &-md-8 {@include col8;}
    &-md-9 {@include col9;}
    &-md-10{@include col10;}
    &-md-11{@include col11;}
    &-md-12{@include col12;}
  }
  .offset{
    &-md-0 {@include off0;}
    &-md-1 {@include off1;}
    &-md-2 {@include off2;}
    &-md-3 {@include off3;}
    &-md-4 {@include off4;}
    &-md-5 {@include off5;}
    &-md-6 {@include off6;}
    &-md-7 {@include off7;}
    &-md-8 {@include off8;}
    &-md-9 {@include off9;}
    &-md-10{@include off10;}
    &-md-11{@include off11;}
    &-md-12{@include off12;}
  }
}

@include min-lg{
  .col{
    &-lg-1 {@include col1;}
    &-lg-2 {@include col2;}
    &-lg-3 {@include col3;}
    &-lg-4 {@include col4;}
    &-lg-5 {@include col5;}
    &-lg-6 {@include col6;}
    &-lg-7 {@include col7;}
    &-lg-8 {@include col8;}
    &-lg-9 {@include col9;}
    &-lg-10{@include col10;}
    &-lg-11{@include col11;}
    &-lg-12{@include col12;}
  }
  .offset{
    &-lg-0 {@include off0;}
    &-lg-1 {@include off1;}
    &-lg-2 {@include off2;}
    &-lg-3 {@include off3;}
    &-lg-4 {@include off4;}
    &-lg-5 {@include off5;}
    &-lg-6 {@include off6;}
    &-lg-7 {@include off7;}
    &-lg-8 {@include off8;}
    &-lg-9 {@include off9;}
    &-lg-10{@include off10;}
    &-lg-11{@include off11;}
    &-lg-12{@include off12;}
  }
}

@include min-xl{
  .col{
    &-xl-1 {@include col1;}
    &-xl-2 {@include col2;}
    &-xl-3 {@include col3;}
    &-xl-4 {@include col4;}
    &-xl-5 {@include col5;}
    &-xl-6 {@include col6;}
    &-xl-7 {@include col7;}
    &-xl-8 {@include col8;}
    &-xl-9 {@include col9;}
    &-xl-10{@include col10;}
    &-xl-11{@include col11;}
    &-xl-12{@include col12;}
  }
  .offset{
    &-xl-0 {@include off0;}
    &-xl-1 {@include off1;}
    &-xl-2 {@include off2;}
    &-xl-3 {@include off3;}
    &-xl-4 {@include off4;}
    &-xl-5 {@include off5;}
    &-xl-6 {@include off6;}
    &-xl-7 {@include off7;}
    &-xl-8 {@include off8;}
    &-xl-9 {@include off9;}
    &-xl-10{@include off10;}
    &-xl-11{@include off11;}
    &-xl-12{@include off12;}
  }
}

.fcol{
	flex-direction: column;
}