&.page-links{
	display: flex;
	min-height: 100VH;
	padding: 0;
	align-items: flex-start;
	justify-content: flex-start;

	.wrapper{
		padding: 1rem 0;
	}
}