// Holmio colors
$holmiored		:#DA2C38;
$holmiobrown	:#BD643D;
$holmiogreen	:#3A6B3D;
$eggshell			:#EDE9D2;
$holmioblue		:#2374AB;

//Status colors
$permanent		:#D0BCB4;
$other-stock	:#83D8CD;
$suficient		:#ADD897;
$limited			:#F0A335;
$only-order		:#D5BEEE;

// Type colors
$white				:#ffffff;
$light-grey-1	:#F4F4F4;
$light-grey-2	:#E0E0E0;
$light-grey		:#8D8D8D;
$dark					:#161616;
$black				:#000000;