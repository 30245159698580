$borderRadius:2px;

@mixin vertical-scrollbar($auto:auto) {
  &::-webkit-scrollbar {
    width:4px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $light-grey-2;
    border-radius:2px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color: $light-grey-1;
    outline: none;
    border-radius:2px;
    cursor: drag;
  }
  overflow-x: hidden;
  @if $auto == auto {
    overflow-y: auto;
  } @else {
    overflow-y: scroll;
  }
}

@mixin horizontal-scrollbar($auto:auto) {
  &::-webkit-scrollbar {
    height:4px;
    border-radius:2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: $light-grey-2;
    border-radius:2px;
  } 
  &::-webkit-scrollbar-thumb {
    background-color:$light-grey-1;
    outline: none;
    border-radius:2px;
  }
  overflow-y: hidden;
  @if $auto == auto {
    overflow-x: auto;
  } @else {
    overflow-x: scroll;
  }
}

@mixin main-transition {
  transition: all 0.25s ease-in-out;
}

@mixin placeholder($color:white,$opacity:1){
  &::-webkit-input-placeholder {
    color: $color;
    opacity:$opacity;
  }
  &::-moz-placeholder {
    color:$color;
    opacity:$opacity;
  }
}

@mixin truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamp($font-size,$line-height,$lines-to-show) {
  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  font-size: $font-size;
  maxheight: calc(#{$font-size} * #{$line-height} * #{$lines-to-show}); // Fallback for non-webkit browsers
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}