.header{
	background: $white;
	height: 80px;
	position: fixed;
	z-index: 10;
	top:0;
	right:0;
	left:0;
	padding: 18px 0;
	display: flex;
	align-items: center;
	>.container{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo{
		height: 46px;
		width: auto;
	}
	.right{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		>.inner{
			display: flex;
			> .btn{
				margin-right: 16px;
			}
		}
		@include max-sm{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 0;
			width: 100%;
			height: 80px;
			position: fixed;
			right:0;
			bottom:0;
			left:0;
			top:80px;
			opacity:0;
			padding:0;
			pointer-events: none;
			transition: all 0.25s ease-out;
			overflow: hidden;
			transition-delay: 0.25s;
			>.inner{
				background: #f9f9f9;
				height:0;
				transition: all 0.25s ease-out;
				transition-delay: 0.15s;
				display: flex;
				align-items: flex-start;
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: $gutter;
				overflow: hidden;
				overflow-y: auto;
			}
			&.active{
				opacity: 1;
				pointer-events: all;
				height:calc( 100vh - 80px);
				transition-delay: 0;
				>.inner{
					height:calc( 100vh - 80px);
				}
				+ .btn-toggle{
					span{
						background:transparent;
						transform: rotate(180deg);
						&::before{
							top:0;
							margin-top: -8px;
							transform: rotate(45deg);
							margin-left: 3px;

						}
						&::after{
							bottom:0;
							margin-bottom: -8px;
							transform: rotate(-45deg);
							margin-left: 3px;
						}
					}
				}
			}
		}
	}
	.btn-toggle{
		background: $eggshell;
		width: 44px;
		height: 44px;
		border-radius: 4px;
		border:0;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			display: flex;
			width: 22px;
			height: 1px;
			background: $dark;
			position: relative;
			transition: all 0.25s ease-out;
			pointer-events: none;
			&::before,
			&::after{
				content: '';
				position: absolute;
				width: 22px;
				height: 1px;
				background: $dark;
				transition: all 0.25s ease-out;
			}
			&::before{
				top:0;
				margin-top: -7px;
				transform-origin: 0 0;
			}
			&::after{
				bottom:0;
				margin-bottom: -7px;
				transform-origin: 0 100%;
			}
		}
	}
}