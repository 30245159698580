.footer{
	padding: 48px 0 40px;
	@include max-sm{
		padding-right: $gutter;
		padding-left:$gutter;
	}
	.footer-title{
		margin: 0 0 40px;
		@include ptype-base-plus-2('bold');
		@include max-sm{
			margin-bottom: 15px;
		}
	}
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
		li{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0 0 8px;
			@include max-sm{
				margin: 0;
			}
			.round-img{
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				overflow: hidden;
				>img{
					width: 32px;
					height: 32px;
					display: flex;
					object-fit: cover;
				}
			}
			a{
				&:hover{
					color:$eggshell;
				}
			}
		}
	}
	.left{
		ul{
			li{

				.btn.btn-link{
					>.inner{
						padding: 0 10px 2px;
					}
					
				}
			}
		}
		@include max-sm{
			order:2;
		}
	}
	.right{
		ul{
			@include max-sm{
				margin-bottom: 40px;
			}
			li{
				a,p{
					margin: 0 0 8px;
					color: $white;
					@include ptype-base-0("bold");
					@include max-sm{
						margin: 0 0 7px;
					}
				}
			}
		}
		@include max-sm{
			order:1;
		}
	}
}