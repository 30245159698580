.magic-input{
	position: relative;
	.form-control{
		display: flex;
		background: $white;
		width: 100%;
		height: 58px;
		border-radius: 8px;
		border: solid 1px $eggshell;
		>input{
			width: 100%;
			height: 56px;
			border:0;
			outline: none;
			border-radius: 8px;
			overflow: hidden;
			padding:0 16px;
			@include ptype-base-0;
			line-height: 56px;
			color: $dark;
			@include placeholder($dark,1);
		}
	}
	&.error{
		.label{
			color: $holmiored;
			opacity:1;
		}
		.form-control{
			border-color: $holmiored !important;
		}
	}
	.label{
		@include ptype-base-0;
		color: $white;
		opacity: 0;
		margin-bottom: 8px;
		position: absolute;
		font-size: 11px;
		font-weight: 400;
		padding:2px 0 0 16px;
		pointer-events: none;
	}
}
