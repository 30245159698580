.confirmation-component{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  .loader-screen{
    @include main-transition;
    opacity:1;
  }
  &.faded{
    .loader-screen{
      opacity: 0;
    }
    .confirm{
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.6s;
    }
  }
  .confirm{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(-60px);
    transition: all 0.85s ease-out;
    .top{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      .heading{
        color:$eggshell;
        @include e-ptype(36);
        margin: 0 0 8px;
      }
      .desktop-text{
        @include ptype-base-regular(2);
        margin: 0 0 40px;
      }
    }
    .bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      margin:$gutter*2 0 $gutter*4;
    }
    .btn-back{
      width: auto;
      height: auto;
      min-height: 58px;
      border-radius: 8px;
      overflow: hidden;
      background: $white;
      padding: 0 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #161616;
      @include ptype-base-regular(0);
      font-weight: 700;
      border:0;
      outline: none;
      @include main-transition;
      cursor: pointer;
      &:hover,
      &:focus{
        box-shadow: 0 2px 5px rgba(0,0,0,.25);
      }
    }
  }
}