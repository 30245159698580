&.index{
  user-select: none;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include max-md{
    background-size: contain;
  }
}