// Heading font
// https://fonts.google.com/specimen/Alegreya
@mixin e-ptype($type){
  font-family: $heading;
  font-weight: 800;
  @if $type == 36 {
    font-size: 36px;
    line-height: 37px;
  }
  @else if $type == 20 {
    font-size: 20px;
    line-height: 24px;
  }
}

// Body font
// https://fonts.google.com/specimen/IBM+Plex+Sans#standard-styles
@mixin ptype-base-regular($type){
  font-family: $pairing;
  font-weight: 400;
  @if $type == 2{
    font-size: 20px;
    line-height: 24px;
  } @else if $type == 0{
    font-size: 16px;
    line-height: 19px;
  }
}

@mixin ptype-base-bold($type){
  font-family: $pairing;
  font-weight: 700;
  @if $type == 0{
    font-size: 16px;
    line-height: 19px;
  }
}

// New pages fonts
@mixin e-type($size:48){
  font-family:$heading;
  font-size: 64px;
  line-height: 80px;
  font-weight: 800;
  letter-spacing: 0px;
  @if $size == 36 {
    font-size: 36px;
    line-height: 48px;
  } @else if $size == 24 {
    font-size: 24px;
    line-height: 40px;
  } @else if $size == 22 {
    font-size: 22px;
    line-height: 32px;
  } @else if $size == 20 {
    font-size: 20px;
    line-height: 24px;
  } @else if $size == 18{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
  } @else if $size == 16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
  } @else if $size == 12 {
    font-size: 12px;
    line-height: 16px;
  }
}

@mixin ptype-base-3($weight:"regular"){
  font-family: $pairing;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0;
  @if( $weight == "bold"){
    font-weight: 700;
  } @else if $weight == "light"{
    font-weight: 300;
  } @else if $weight == "italic"{
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono"{
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-2($weight:"regular"){
  font-family: $pairing;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold"{
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-1($weight:"regular"){
  font-family: $pairing;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-0($weight:"regular"){
  font-family: $pairing;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold"{
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-plus-2($weight:"regular"){
  font-family: $pairing;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
   }@else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-plus-3($weight:"regular"){
  font-family: $pairing;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-plus-4($weight:"regular"){
  font-family: $pairing;
  font-size: 28px;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}

@mixin ptype-base-plus-5($weight:"regular"){
  font-family: $pairing;
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
  letter-spacing: 0;
  @if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "italic" {
    font-weight: 400;
    font-style: italic;
  } @else if $weight == "mono" {
    font-family:$pairing-mono;
  }
}