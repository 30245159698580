&.page-customer{
	.form-content{
		max-width: 680px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title{
			font-family: Alegreya;
			font-size: 64px;
			font-weight: 800;
			line-height: 64px;
			text-align: center;
			margin:0 0 18px;
			@include max-sm{
				font-size: 28px;
				line-height: 28px;
				margin: 0 0 33px;
			}
		}
		p{
			@include ptype-base-plus-3('regular');
			margin:0 0 65px;
			text-align: center;
		}
		.line-form{
			display: flex;
			flex-wrap: wrap;
			.btn{
				height: 58px;
				margin-bottom: 8px;
				line-height: 56px;
			}
		}
		.phone-wrapper{
			max-width: 400px;
			margin-right: 8px;
			margin-bottom: 8px;
		}
	}
	.section-form-wrapper .stores{
		margin: 0;
	}
	.teaser-slider{
		&:not(.bg-left){
			@include min-sm{
				background-size: 560px;
			}
		}
	}
}