.holmio-layout{
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
  header{
    + main{
      padding-top: 80px;
			display: flex;
			flex-grow: 1;
			width: 100%;
			flex-direction: column;
      > div{
        justify-content: flex-start;
        align-items: flex-start
      }
    }
  }

	.footer{
		display: flex;
		width: 100%;
		background: $dark;
		border:0;
		@include ptype-base-bold(0);
		color:$white;
	}
}                     