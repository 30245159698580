&.page-farmer{
	user-select:none;
	.section-community{
		@include max-sm{
			background-color:$light-grey-1;
		}
	}
	.section-form-wrapper{
		&.farmer-form{	
			background-position: 35% 101%;
			padding-top: 80px;
			padding-bottom: 180px;
			background-size: 494px;
			@include max-sm{
				padding-top: 41px;
				background-image: none !important;
				padding-bottom: 0;
				background: $white !important;
			}
			.title{
				@include e-type;
				line-height: 64px;
				margin: 0 0 $gutter*2;
				text-align: center;
				@include max-sm{
					font-size: 28px;
					line-height: 28px;
				}
			}
			p{
				@include ptype-base-plus-3("regular");
				margin:0 0 $gutter*4;
				@include max-sm{
					line-height: 29px;
				}
				&.ta-center{
					@include max-sm{
						text-align: left;
					}
				}
			}

			.phone-wrapper,
			.magic-input{
				margin-bottom: 16px;
			}
			.phone-wrapper{
				.phone-with-flag{
					border-color:$light-grey-2;
					.flag-drop .list ul li{
						background-color: $white;
					}
				}
			}
			.magic-input{
				.form-control {
					border-color:$light-grey-2;
				}
			}
			.btn.btn-green.btn-submit{
				display: inline-flex;
				margin-left: auto;
			}
		}
	}
}