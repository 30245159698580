.lang-drop{
	position: relative;
	z-index: 2;
	&.active{
		ul{
			opacity: 1;
			pointer-events: all;

			li{
				transform: translateY(0%);
				+ li{
					transform: translateY(0%);
					transition-delay:0.05s;
					+ li{
						transform: translateY(0%);
						transition-delay:0.1s;
					}
				}
			}
		}
		
	}
	.btn{
		width: 71px;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
	}
	ul{
		position: absolute;
		z-index: 1;
		top:100%;
		left:0;
		margin:0;
		padding: 0;
		list-style: none;
		width: 100%;
		opacity: 0;
		pointer-events: none;
		margin-top: 1px;
		@include main-transition;
		li{
			transform: translateY(-100%);
			@include main-transition;
			.btn{
				border-radius: 0;
			}
			&:first-child{
				.btn{
					border-radius: 4px 4px 0 0;
				}
			}
			&:last-child{
				.btn{
					border-radius:0 0 4px 4px;
				}
			}
			+ li{
				transform: translateY(-200%);
				+ li{
					transform: translateY(-300%);
				}
			}
		}
	}
}