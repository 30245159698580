@import 'class-mixins';
@import 'margins';
@import 'paddings';
@import 'positions';
@import 'grid';


.flex{ display: flex;}
.jc-center{	justify-content: center;}
.ai-center{ align-items: center;}
.ta-center{ text-align: center;}