.teaser-slider{
	width: 100%;
	display: flex;
	margin:0;
	border-style: solid;
	border-width: 0 0 1px;
	border-color: #FFEAEA;
	background-color: $white;
	background-position: bottom right;
	background-repeat: no-repeat;
	padding: $gutter*3 0 140px;
	color:$dark;
	@include max-md{
		background-size: 90vw;
	}
	.page-customer &{
		border-width: 0;
	}
	.page-farmer &{
		@include min-sm{
			background-size: 837px ;
		}
	}
	@include max-sm{
		padding-right: $gutter;
		padding-left: $gutter;
		padding-bottom: 108px;
		background-size: 99%;
    background-position: 900% 100%;
		border-color: #BD643D;
		.page-customer &{
			padding-bottom: 171px;
			background-position: 1834% 100%;
		}
	}
	&.bg-left{
		background-position: 20vw bottom;
		padding: $gutter*3 0 240px;
		background-size: 492px;
		@include min-sm{
			padding: 110px $gutter;
		}
		@include max-sm{
			padding-right: $gutter;
			padding-left: $gutter;
			background-size: 79%;
    	background-position: 86% 105%;
			.page-customer & {
				//background-image: none !important;
				padding-bottom: 133px;
				background-color:#fff;
				padding-top: 48px;
			}
			.left-side{
				order:1;
				.title{
					.page-customer & {
						margin:0 0 36px;
					}
				}
				p{
					.page-customer & {
						margin-bottom: 30px;
						display: flex;
					}
				}
			}
			.right-side{
				order:2;
				.slider-centered{
					.page-customer &{
						@include max-sm{
							width: calc(100% + #{$gutter*3});
							margin-right: -$gutter*2;
							margin-left:  -$gutter;
						}
					}
				}
			}
		}
	}
	@include min-sm{
		padding: 110px 0;
	}
	&:not(.bg-left){
		.left-side{
			@include max-sm{
				padding: 0 !important;
			}
		}
	}
	.left-side{
		order:2;
		@include min-sm{
			order:1
		}
		@include max-sm{
			p{
				.page-customer & {
					display: none;
				}
			}
		}
	}
	.right-side{
		order:1;
		@include min-sm{
			order:2
		}
	}

	.slider-centered{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;	
		@include max-sm{
			width: calc(100% + #{$gutter});
			margin-right: -$gutter;
		}
	}
	.slider-wrapper{
		display: block;
		width: 100%;
		@include min-sm{
			width: 295px;
		}
		.slick-list{
			.item{
				user-select: none;
				outline:none;
				img{
					max-width: 294px;
					outline: none;
					@include max-sm{
						max-width: 254px;
					}
				}
				@include max-sm{
					padding:0 0 0 16px;
				}
			}
		}
	}
	.title{
		min-height: 80px;
		font-family: $heading;
		font-size: 36px;
		font-weight: 800;
		line-height: 40px;
		margin: 60px 0;	
		@include max-sm{
			font-size: 28px;
			line-height: 28px;
			margin: 2px 0 35px;
			min-height: auto;
			.page-customer & {
				margin: 4px 0 50px;
			}
		}
	}
	p{
		margin: 0 0 $gutter*2;
		@include ptype-base-plus-3("bold");
		@include max-sm{
			margin: 0 0 $gutter*4;
			font-size: 20px;
    	line-height: 24px;
			padding-right: 12px;
		}
	}
}

.slick-dots{
	padding: 0;
	text-align: center;
	@include max-sm{
		display: none !important;
	}
	li{
		margin:0 6px;
		display: inline-block;
		button{
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border:0;
			outline: none;
			margin: 0;
			padding: 0;
			text-indent: 20px;
			overflow: hidden;
			background: #E0E0E0;
			cursor: pointer;
			transition: all 0.25s ease-out;
		}
		
		&.slick-active{
			button{
				background: #C6C6C6;
			}
		}
	}
}

