.phone-with-flag{
	display: flex;
	background: $white;
	width: 100%;
	height: 58px;
	border-radius: 8px;
	//max-width: 400px;
	border:solid 1px $eggshell;
	.flag-drop{
		width: 85px;
		background: transparent;
		border-radius: 6px 0 0 6px;
		position: relative;
		&.active{
			.arrow{
				transform: rotate(180deg);
			}
			.list{
				opacity: 1;
				z-index: 15;
				top:56px;
				left: 0;
				pointer-events: all;
			}
		}
		.btn-drop{
			border:0;
			outline: none;
			margin:16px 0 0 16px;
			padding: 0;
			background: transparent;
			cursor: pointer;
		}
		.arrow{
			position: absolute;
			top: 24px;
			left: 60px;
			width: 12px;
			height: 6px;
			display: flex;
			&::before,
			&::after{
				content:"";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 6px 0 6px;
				border-color: $dark transparent transparent;
				position: absolute;
				top:1px;
				left:0;
				z-index: 1;
			}
			&::after{
				border-color: $white transparent transparent;
				top:0;
				z-index: 2;
			}
		}

		.list{
			list-style: none;
			position: absolute;
			z-index: 1;
			top:68px;
			left: 0;
			margin:0;
			padding:2px 0;
			width: 138px;
			border-radius: 8px 4px 4px 8px;
			transition: all 0.25s ease-out;
			opacity: 0;
			box-sizing: border-box;
			pointer-events: none;
			ul{
				width: 100%;
				display: flex;
				flex-direction: column;
				max-height:40vh;
				margin:0;
				padding:0;
				overflow-x: hidden;
				overflow-y: auto;
					&::-webkit-scrollbar {
						width:2px;
						border-radius:1px;
					}
					&::-webkit-scrollbar-track {
						box-shadow: none;
						background-color: transparent;
						border-radius:1px;
					} 
					&::-webkit-scrollbar-thumb {
						background-color:black;
						outline: none;
						border-radius:1px;
						cursor: drag;
					}
				>li{
					background: $white;
					cursor: pointer;
					&:first-child{
						padding-top:8px;
						border-radius: 8px 4px 0 0;
					}
					&:last-child{
						padding-bottom: 8px;
						border-radius: 0 0 4px 8px;
					}
					.bt{
						border:0;
						outline: none;
						background: transparent;
						display: flex;
						padding: 8px 0 8px 16px;
						cursor: pointer;
					}
					.country{
						line-height: 24px;
						padding-left: 37px;
					}
				}
			}
		}
	}

	&.drop-up{
		.flag-drop{
			&.active{
				.list{
					top:unset;
					bottom:56px;
				
				}
			}
		}
		.flag-drop{
			.list{
				top:unset;
				bottom:68px;
				ul{
					justify-content: flex-end;
				}
			}
		}
	}
	.input-wrapper{
		display: flex;
		flex-grow: 1;
		position: relative;
		width: calc(100% - 85px);
		overflow: hidden;
		.cc{
			@include ptype-base-0;
			line-height: 56px;
			color: $dark;
			width: 42px;
			min-width: 42px;
		}
		.input-phone{
			display: flex;
			flex-grow: 1;
			border:0;
			outline: none;
			background-color: transparent;
			padding: 0 0 0 5px;
			@include ptype-base-0;
			line-height: 56px;
			color: $dark;
			@include placeholder(#c6c6c6,1);
			padding-right:45px;
			box-sizing: border-box;
		}
		.valid{
			position: absolute;
			z-index: 1;
			top:50%;
			right:13px;
			pointer-events: none;
			width:24px;
			height: 24px;
			display: flex;
			background:$holmiogreen;
			border-radius: 50%;
			transform: translateY(-50%);

			&::before,
			&::after{
				content:'';
				background: $white;
				height: 2px;
				display: flex;
				position:absolute;
				top:0;
				left:0;
			}
			&::before{
				width: 6px;
				transform: rotate(45deg);
				margin: 12px 0 0 5px;
			}
			&::after{
				width: 12px;
				transform: rotate(-45deg);
				margin: 10px 0 0 7px;
			}
		}
	}

}

.phone-wrapper{
	position: relative;
	user-select: none;
	&.error{
		.phone-with-flag{
			border-color:$holmiored !important;
		}
		.input-wrapper .cc,
		.input-phone{
			color: $holmiored;
		}
	}

	.label{
		@include ptype-base-0;
		color: $holmiored;
		margin-bottom: 8px;
		position: absolute;
		font-size: 11px;
		font-weight: 400;
		padding:2px 0 0 87px;
		pointer-events: none;
	}
}