.btn{
  display: inline-flex;
  color: $white;
  background-color:transparent;
  padding:1px;
  border-style: solid;
  border-width: 0;
  border-color: transparent;
  text-decoration: none;
  outline: none;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  @include main-transition;
	@include ptype-base-bold(0);
	> div{
		padding: 0 23px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 42px;
	}
  ~ .btn{
    margin-left: 16px;
  }

  &.btn{
		
    &-secondary{
      background: $eggshell;
			color: $dark;
      &:hover{
        background: $eggshell;
				color: $dark;
			}
    }

    &-green{
      background: $holmiogreen;
			color: $eggshell;
      &:hover{
        background:lighten( $holmiogreen, 3% );
				color: $white;
        box-shadow: 0 2px 8px rgba(0,0,0,.2);
			}
      &:active{
        background:darken( $holmiogreen, 3% );
				color: $white;
        box-shadow: 0 2px 8px rgba(0,0,0,.2) inset;
      }
    }
  }
}