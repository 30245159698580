.section-community{
	width: 100%;
	display: flex;
	margin: 0;
	padding: 80px 0;
	background-color: $white;
	min-height: 100vh;
	@include max-lg{
		padding: 80px $gutter;
		min-height: auto;
	}
	.community-wrapper{
		border-radius: 15px;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		position: relative;
		padding:120px 128px 120px 82px;
		@include max-lg{
			padding: 80px 40px;
		}
		@include max-sm{
			padding: $gutter*4 $gutter*2;
		}
		.left-side{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include max-sm{
				align-items: center;
			}
			.top{
				img{
					display: flex;
					margin: $gutter*2 auto $gutter*4;
					@include max-sm{
						max-width: 254px;
						margin-bottom: 57px;
					}
				}
			}
			.title{
				font-family: $heading;
				font-size: 36px;
				font-weight: 800;
				line-height: 40px;
				color:$eggshell;
				margin:35px 0 $gutter*2;
				@include max-sm{
					margin:0 0 $gutter*2;
				}
			}
			p{
				@include ptype-base-plus-3("bold");
				margin: 0 0 $gutter*2;
				@include max-sm{
					font-size: 16px;
    			line-height: 19px;
				}
			}
			.list{
				@include ptype-base-plus-3("bold");
				margin:0 0 $gutter*3;
				color:$eggshell;
				@include max-sm{
					font-size: 16px;
    			line-height: 19px;
					color:$white;
					margin-bottom: 27px;
				}
				&-sigle{
					.sigle{
						width: 80px;
						height: 80px;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						margin: 16px 16px 0 0;
						@include max-sm{
							margin-top: 22px;
						}
						>.inner{
							width: 80px;
							height: 80px;
							border-radius: 50%;
							overflow: hidden;
							display: inline-flex;
							>img{
								width: 80px;
								height: 80px;
								min-width: 80px;
								border-radius: 50%;
								overflow: hidden;
								display: inline-flex;
							}
						}
						@media (max-width:460px) {
							width: 33.3333%;
							flex: 0 0 33%;
							margin-right: 0;
							&:nth-of-type(1),
							&:nth-of-type(4){
								justify-content: flex-start;
							}
							&:nth-of-type(3),
							&:nth-of-type(6){
								justify-content: flex-end;
							}
						}
					}
				}
			}
		}
		.list-sigle{
			display: flex;
			flex-wrap: wrap;
		}
	
		.img-centered{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			>img{
				max-width: 294px;
			}
			@include max-sm{
				padding-top: $gutter*2;
			}
		}
	}
}