.holmio-map{
	width: 100%;
	height: auto;
	min-height:calc(100vh - 80px);
	display: flex;
	margin: 0;
	padding: 0;
	background-size: cover;
	background-position: center;
	@include max-sm{
		min-height: 500px;
	}
	@include max-xs{
		min-height:calc(100vh - 150px);
	}
	>.container{
		padding-top:80px;
		padding-bottom:80px;
		background-size: 70vh;
		background-repeat: no-repeat;
		background-position: center 145%;
		display: flex;
		align-items: center;
		@include max-sm{
			background-size: 50vh;
			background-position: 115% 180%;
		}
		@include max-xs{
			background-position: 2vw 5vh;
			align-items: flex-end;
			padding-bottom: $gutter;
		}
		.title{
			color:$dark;
			@include e-type(36);
			margin-top: $gutter*4;
			margin-bottom: 0;
			max-width: 380px;
			@include max-sm{
				@include e-type(24);
				line-height: 28px;
			}
		}
	}
	&.mobile{
		flex-direction: column;
		padding:$gutter*4 $gutter*2;
		.title{
			color:$dark;
			@include e-type(24);
			line-height: 28px;
			margin:0 0 $gutter*3;
		}
		img{
			width: 100%;
			max-width: 254px;
			margin:0 auto;
		}
	}
}