html,body{
  margin: 0;
  padding:0;
  color:$white;
  min-height: 100vh;
  background: $holmiored; 
}

body{
  @include vertical-scrollbar;
  &.locked{
    overflow:hidden;
    height: 100vh;
  }
}

*,*::before,*:after{
  box-sizing: border-box;
}

#root{
  background: $holmiored;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  color: $white;
  @include ptype-base-regular(0);
  @include min-sm{
    @include ptype-base-regular(2);
  }
}

a:not(.btn){
  color: #4a91c3;
  text-decoration: none;
  outline: none;
  &:hover{
    color:#1089dd;
    text-decoration: none;
    outline: none;
  }
}

p{
  a{
    padding: 0 3px;
  }
}

.heading{
  &-1,
  &-2,
  &-3,
  &-4,
  &-5,
  &-6{
    margin: 0;
  }
}