.section-hero{
	width: 100%;
	min-height: calc(100vh - 80px);
	display: flex;
	overflow: hidden;
	background-size:878px,857px;
	background-repeat: no-repeat , no-repeat;
	background-position:  100% 100% , 84% 100%; // 132% -538%,84% 93%;
	padding: $gutter*3 0 80px;
	@include max-sm{
		background-size: 145%;
		padding: $gutter*3 0 175px;
		background-position: 52% 100%;
	}
	.container{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		&.customer{
			justify-content: space-between;
			@include max-md{
				justify-content: flex-start;
			}
		}
		@include max-md{
			justify-content: flex-start;
		}
	}
	.wrapper{
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
		.form-wrapper{
			margin: $gutter*4 0 $gutter;
		}
	}
	.stores{
		display: flex;
		padding: 0 $gutter;
		.btn-transparent{
			margin-right: 8px;
		}
	}
	.heading{
		&-1{
			@include e-type(48);
			line-height: 64px;
			color:$eggshell;
			margin: 0 0 $gutter*2;
			@include max-sm{
				font-size: 36px;
				line-height: 36px;
				margin-top: 40px;
				padding-left:$gutter;
				padding-right: 12%;
				margin-bottom: 30px;
			}
		}
	}
	p{
		@include ptype-base-plus-3;
		margin: 0 0 $gutter*2;
		@include max-sm{
			font-size: 18px;
    	line-height: 27px;
		}
	}
	.btn{
		margin-right: auto;
		margin-bottom: $gutter*2;
		@include max-sm{
			margin-left: $gutter;
		}
	}
	.info-section{
		display: flex;
		&.customer{
			svg{
				margin-top: 5px;
				margin-right: 8px;
			}
			p{
				max-width: 75%;
			}
		}
		svg{
			width: 32px;
			min-width: 32px;
			margin-right: 4px;
		}
		p{
			@include ptype-base-0("regular");
			margin:0 0 $gutter*4;
			max-width: 45%;
		}
	}
}