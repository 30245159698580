header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px $gutter*2 16px;
  @include min-sm{
    padding: 32px $gutter*2;
  }
  img{
    width: 92px;
    display: flex;
  }
  + main{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding:0 $gutter*2 65px;
    >div{
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
    }
    .mobile-text{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 $gutter*3;
      text-align: center;
    }
    .centered-content{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: $gutter*2;
      .text-center{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #00000096;
        padding: $gutter*1.2 $gutter*2;
        border-radius: $gutter;
        text-shadow: 0 1px 2px #0009
      }
    }
    .btn-transparent{
      border:0;
      outline: none;
      margin: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;
    }
  }
}