.section-form-wrapper{
	width: 100%;
	min-height:50vh;
	background-position: center 102%;
	background-repeat: no-repeat;
	padding:180px $gutter 390px;
	color: $dark;
	@include max-sm{
		padding:60px $gutter 160px;
		background-size: 90%;
		.page-customer & {
			padding: 54px $gutter*2 80px;
			background-image: none !important;
			min-height: auto;
		}
	}
	.phone-with-flag .flag-drop .list ul li{
		background: #f9f9f9;
		box-shadow: 0 1px 1px rgba(0,0,0,.25);
	}
	.stores{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 34px 0 48px;
		.btn-transparent{
			margin: 0 8px;
		}
	}
}